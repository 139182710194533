body {
  background-color: #e9e9e9;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.loading-box {
  margin: 0 auto;
}

/* Top page */

.section-login-box {
  width: 760px;
  margin: 0 auto;
  text-align: right;
}

.section-search-box {
  width: 760px;
  margin: 10px auto 10px;
}

.section-search-box > button {
  padding: 8px;
  color: #FFFFFF;
  background-color: #000000;
  margin-left: 10px;
}

.section-search-box > input,
.section-search-box > select
{
  border-radius:10px;
  margin: 10px;
  padding: 8px;
  border: none;
}

.section-item-box {
  display: flex;
  width: 700px;
  height: 180px;
  margin: 20px auto;
  background-color: #FFFFFF;
  padding: 20px;
}

.section-item-image > img {
  width: 200px;
  height: 180px;
}

.section-item-content {
  text-align: left;
  margin-left: 20px;
}

.section-no-item-box > p {
  text-align: center;
  font-size: 20px;
}

/* Login page */

.section-login-menu-box {
  width: 520px;
  margin: 0 auto;
}

.section-login-item-box {
  width: 480px;
  margin: 20px auto;
  background-color: #FFFFFF;
  padding: 20px;
}

.section-login-item-box > div {
  width: 300px;
  margin: auto;
  padding: 10px;
}

.section-login-item-box > div > input {
  width: 300px;
  box-sizing: border-box;
}

.section-login-item-box > div > button {
  width: 300px;
  box-sizing: border-box;
  padding: 10px 0px;
  color: #FFFFFF;
  background-color: #000000;
  border-radius:10px;
}

.section-login-item-box > div > span {
  color: #FF0000;
}

/* List page */

.section-list-menu-box {
  width: 760px;
  margin: 0 auto;
}

.section-list-menu-box > button {
  padding: 8px;
  color: #FFFFFF;
  background-color: #000000;
  margin-left: 30px;
}

/* Regist page */

.section-regist-menu-box {
  width: 520px;
  margin: 0 auto;
}

.section-regist-item-box {
  width: 480px;
  margin: 20px auto;
  background-color: #FFFFFF;
  padding: 20px;
}

.section-regist-item-box > div {
  width: 400px;
  margin: auto;
  padding: 10px;
}

.section-regist-item-box > div > input,
.section-regist-item-box > div > select
{
  width: 400px;
  box-sizing: border-box;
}

.section-regist-item-box > div > button {
  width: 400px;
  box-sizing: border-box;
  padding: 10px 0px;
  color: #FFFFFF;
  background-color: #000000;
  border-radius:10px;
}

.section-regist-item-box > div > span {
  color: #FF0000;
}

/* Edit page */

.section-edit-menu-box {
  width: 520px;
  margin: 0 auto;
}

.section-edit-menu-box > button {
  padding: 8px;
  color: #FFFFFF;
  background-color: #000000;
  margin-left: 30px;
}